import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["href", "target"];
const _hoisted_2 = { class: "button-icon" };
const _hoisted_3 = { class: "button-label" };
const _hoisted_4 = { class: "button-counter" };
const _hoisted_5 = ["disabled"];
const _hoisted_6 = { class: "button-icon" };
const _hoisted_7 = { class: "button-label" };
const _hoisted_8 = { class: "button-counter" };
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ZButton',
    props: {
        href: {
            type: String,
            default: '',
        },
        target: {
            type: String,
            default: '_self',
            validator: (value) => {
                return ['_self', '_blank', '_parent', '_top'].includes(value);
            },
        },
        variety: {
            type: String,
            default: 'optimus',
            validator: (value) => {
                // You can add more variety types here
                return [
                    'optimus',
                    'robin',
                    'phoenix',
                    'cyclops',
                    'rogue',
                    'love',
                    'plum',
                    'magneto',
                    'punisher',
                    'joker',
                ].includes(value);
            },
        },
        mode: {
            type: String,
            default: 'light',
            validator: (value) => {
                // You can add more modes types here
                return [
                    'light',
                    'dark',
                ].includes(value);
            },
        },
        wide: {
            type: String,
            default: 'always',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        compact: {
            type: String,
            default: 'never',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        superCompact: {
            type: String,
            default: 'never',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        rounded: {
            type: String,
            default: 'never',
            validator: (value) => {
                return [
                    'never',
                    'always',
                    'mobile-only',
                    'desktop-only',
                ].includes(value);
            },
        },
        ghost: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        textLink: {
            type: Boolean,
            default: false,
        },
        rated: {
            type: Boolean,
            default: false,
        },
        minion: {
            type: Boolean,
            default: false,
        },
        isIconOnly: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['click'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const classes = computed(() => {
            return {
                [props.variety]: true,
                [props.mode]: true,
                ['wide-' + props.wide]: true,
                ['compact-' + props.compact]: true,
                ['super-compact-' + props.superCompact]: true,
                ['rounded-' + props.rounded]: true,
                ghost: props.ghost,
                disabled: props.disabled,
                anchor: !props.textLink,
                ['text-link']: props.textLink,
                ['class-rated']: props.rated,
                minion: props.minion,
                ['icon-only']: props.isIconOnly
            };
        });
        const handleClick = (e) => {
            if (props.disabled) {
                e.preventDefault();
                e.stopImmediatePropagation();
                return;
            }
            emit('click', e);
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", null, [
                (__props.href !== '')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: __props.href,
                        target: __props.target,
                        class: _normalizeClass(classes.value),
                        onClick: handleClick
                    }, [
                        _createElementVNode("span", _hoisted_2, [
                            _renderSlot(_ctx.$slots, "icon")
                        ]),
                        _createElementVNode("span", _hoisted_3, [
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                        _createElementVNode("span", _hoisted_4, [
                            _renderSlot(_ctx.$slots, "counter")
                        ])
                    ], 10, _hoisted_1))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: _normalizeClass(["z-button", classes.value]),
                        disabled: __props.disabled,
                        onClick: handleClick
                    }, [
                        _createElementVNode("span", _hoisted_6, [
                            _renderSlot(_ctx.$slots, "icon")
                        ]),
                        _createElementVNode("span", _hoisted_7, [
                            _renderSlot(_ctx.$slots, "default")
                        ]),
                        _createElementVNode("span", _hoisted_8, [
                            _renderSlot(_ctx.$slots, "counter")
                        ])
                    ], 10, _hoisted_5))
            ]));
        };
    }
});
