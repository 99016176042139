import { computed, defineComponent, ref } from 'vue';
import { v4 as uuid } from 'uuid';
import ChevronSvg from '@bx-icons/regular/bx-chevron-down.svg';
import { theme, ThemeType } from "@ts/Util/theme";
const isLabeledValue = (object) => typeof object === 'object' && 'label' in object;
const isLabeledValueWithDescription = (object) => isLabeledValue(object) && 'description' in object;
/**
 * Note: This component works with `v-model` so you can use `<form-select v-model="inputVar" ... />` to bind the
 * selected value to some local var.
 */
export default defineComponent({
    name: 'FormSelect',
    components: {
        ChevronSvg,
    },
    props: {
        name: {
            type: String,
            required: true
        },
        items: {
            type: Object,
            required: true,
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        footer: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: ''
        },
        blurOnClose: {
            type: Boolean,
            default: true
        },
        /**
         * Used by vue to bind the input value to `v-model` set on the component.
         */
        modelValue: {
            type: String,
            default: null,
        },
        selectStyleOverrides: {
            type: Object,
            default: () => ({})
        },
        includeChevron: {
            type: Boolean,
            default: true
        },
        itemAlignment: {
            type: String,
            default: 'left',
            validator: (value) => {
                return ['left', 'center', 'right'].includes(value);
            }
        },
        tabindex: {
            type: Number,
            default: 0
        },
        scrollAfter: {
            type: Number,
            default: 0,
        },
        bubbleItems: {
            type: Array,
            default: () => {
                return [];
            }
        },
        /**
         * Keeps the select enabled
         * but allows to disable individual items
         */
        disableItems: {
            type: Array,
            default: () => []
        },
        /**
         * These two props allow using the select in a somewhat passive fashion
         * ie. controlling from a parent. (we can still model bind and listen to events)
         * note `passiveOpen` will never have any effect if `passive` is false
         */
        passive: {
            type: Boolean,
            default: false
        },
        passiveOpen: {
            type: Boolean,
            default: false
        },
        /**
         * Forces the browser to show a scrollbar on the dropdown
         */
        forceShowScrollbar: {
            type: Boolean,
            default: false
        },
        displayValueOverride: {
            type: String,
            default: ''
        }
    },
    emits: ['update:model-value'],
    setup(props, { emit }) {
        const isZumbaThemed = theme.value === ThemeType.Zumba;
        const selectedBubble = ref(false);
        const root = ref(null);
        const interacted = ref(false);
        const id = ref(uuid());
        const selecting = ref(false);
        const scrollClass = computed(() => {
            return props.scrollAfter > 0 &&
                Object.keys(props.items).length > props.scrollAfter ? 'scroll' : '';
        });
        const blur = (event) => {
            if (event.relatedTarget && (event.relatedTarget === root.value ||
                root.value?.contains(event.relatedTarget))) {
                return;
            }
            close();
        };
        const scrollbarClass = computed(() => {
            return props.forceShowScrollbar ? 'force-scrollbar' : '';
        });
        const optionsOpen = computed(() => {
            if (props.disabled) {
                return false;
            }
            if (props.passive) {
                return props.passiveOpen;
            }
            return selecting.value;
        });
        const dropdownOptions = computed(() => {
            const options = {};
            for (const key in props.items) {
                const option = {
                    key,
                    label: '',
                    description: '',
                    classes: ['item-link'],
                    disabled: props.disableItems.includes(key),
                    bubble: props.bubbleItems.includes(key),
                };
                if (key === props.modelValue) {
                    option.classes.push('selected');
                }
                if (option.bubble) {
                    option.classes.push('bubble');
                }
                if (option.disabled) {
                    option.classes.push('disabled');
                }
                if (isZumbaThemed) {
                    option.classes.push('f-inter');
                }
                const item = props.items[key];
                if (isLabeledValue(item)) {
                    option.label = item.label;
                    if (isLabeledValueWithDescription(item)) {
                        option.description = item.description;
                    }
                }
                else if (['string', 'number'].includes(typeof item)) {
                    // Value is scalar
                    option.label = item.toString();
                }
                else {
                    throw Error(`Unsupported value type for option ${key}`);
                }
                if (option.description.length > 0) {
                    option.classes.push('multi-line');
                }
                options[option.label] = option;
            }
            return options;
        });
        const selectedText = computed(() => {
            if (props.displayValueOverride) {
                return props.displayValueOverride;
            }
            else {
                const selected = Object.values(dropdownOptions.value).filter(option => {
                    return option.key === props.modelValue;
                });
                if (selected.length) {
                    updateSelectedBubble(selected[0].classes.includes('bubble'));
                }
                return selected.length > 0 ? selected[0].label : props.placeholder || '';
            }
        });
        const updateSelectedBubble = (include) => {
            selectedBubble.value = include;
        };
        const toggleSelections = () => {
            if (props.disabled) {
                return;
            }
            selecting.value ? close() : open();
        };
        const open = () => {
            if (props.disabled) {
                return;
            }
            root.value?.focus();
            selecting.value = true;
        };
        const close = () => {
            if (props.disabled) {
                return;
            }
            if (props.blurOnClose) {
                root.value?.blur();
            }
            selecting.value = false;
        };
        const onSelect = (item) => {
            interacted.value = true;
            close();
            emit('update:model-value', item.key);
        };
        return {
            root,
            interacted,
            id,
            selecting,
            scrollClass,
            scrollbarClass,
            optionsOpen,
            dropdownOptions,
            selectedText,
            selectedBubble,
            isZumbaThemed,
            toggleSelections,
            open,
            close,
            onSelect,
            blur
        };
    },
});
