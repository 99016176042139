import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys } from "vue";
const _hoisted_1 = ["aria-owns", "tabindex"];
const _hoisted_2 = ["id", "name", "value", "disabled"];
const _hoisted_3 = {
    key: 0,
    class: "form-select-label"
};
const _hoisted_4 = {
    key: 0,
    class: "options-container"
};
const _hoisted_5 = ["aria-disabled", "onClick"];
const _hoisted_6 = {
    key: 0,
    class: "dropdown-description"
};
const _hoisted_7 = {
    key: 0,
    class: "footer"
};
const _hoisted_8 = { class: "error-msg" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_chevron_svg = _resolveComponent("chevron-svg");
    return (_openBlock(), _createElementBlock("div", {
        ref: "root",
        class: _normalizeClass(["container", { 'f-inter': _ctx.isZumbaThemed }]),
        role: "combobox",
        "aria-owns": 'listbox-' + _ctx.id,
        tabindex: _ctx.tabindex,
        onBlur: _cache[0] || (_cache[0] =
            //@ts-ignore
            (...args) => (_ctx.blur && _ctx.blur(...args))),
        onClick: _cache[1] || (_cache[1] = _withModifiers(
        //@ts-ignore
        (...args) => (_ctx.toggleSelections && _ctx.toggleSelections(...args)), ["prevent"])),
        onKeyup: [
            _cache[2] || (_cache[2] = _withKeys(
            //@ts-ignore
            (...args) => (_ctx.close && _ctx.close(...args)), ["esc"])),
            _cache[3] || (_cache[3] = _withKeys(
            //@ts-ignore
            (...args) => (_ctx.open && _ctx.open(...args)), ["down"]))
        ]
    }, [
        _createElementVNode("input", {
            id: _ctx.id,
            name: _ctx.name,
            value: _ctx.modelValue,
            disabled: _ctx.disabled,
            type: "hidden"
        }, null, 8, _hoisted_2),
        (_ctx.label)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
        _createElementVNode("div", {
            class: _normalizeClass(["select", {
                    dirty: _ctx.interacted,
                    error: _ctx.error.length > 0,
                    selected: _ctx.modelValue,
                    labeled: !!_ctx.label.length,
                    disabled: _ctx.disabled,
                    selecting: _ctx.selecting,
                }]),
            style: _normalizeStyle(_ctx.selectStyleOverrides)
        }, [
            _createElementVNode("span", {
                class: _normalizeClass(['selected-text', { 'bubble': _ctx.selectedBubble }])
            }, _toDisplayString(_ctx.selectedText), 3),
            (_ctx.includeChevron)
                ? (_openBlock(), _createBlock(_component_chevron_svg, {
                    key: 0,
                    class: "chevron"
                }))
                : _createCommentVNode("", true)
        ], 6),
        _createVNode(_Transition, { name: "drop-top" }, {
            default: _withCtx(() => [
                (_ctx.optionsOpen)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["options", [_ctx.scrollClass, _ctx.scrollbarClass]]),
                            role: "listbox"
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dropdownOptions, (properties, text, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: _normalizeClass(["option-container", {
                                            [`content-align-${_ctx.itemAlignment}`]: _ctx.itemAlignment,
                                        }]),
                                    role: "option"
                                }, [
                                    _createElementVNode("a", {
                                        class: _normalizeClass(properties.classes),
                                        "aria-disabled": properties.disabled,
                                        onClick: _withModifiers(($event) => (_ctx.onSelect(properties)), ["prevent", "stop"])
                                    }, [
                                        _createElementVNode("div", null, _toDisplayString(text), 1),
                                        (properties.description.length)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(properties.description), 1))
                                            : _createCommentVNode("", true)
                                    ], 10, _hoisted_5)
                                ], 2));
                            }), 128)),
                            (_ctx.footer)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.footer), 1))
                                : _createCommentVNode("", true)
                        ], 2)
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.error), 1)
    ], 42, _hoisted_1));
}
